
import MainMenuConfig from '@/core/config/MainMenuConfig'

export default {
  name: "Menu",
  components: {
    MainMenuConfig,
  },
  computed: {
    headerMenuIcons() {
      return true;
    },
    isProfileVendorAvailable() {
      const currentProfile = this.$store.state.users.getCurrentProfileResult;
      return currentProfile?.vendor;
    },
  },
  data() {
    return {
      MainMenuConfig: MainMenuConfig
    }
  },
  mounted() {
    this.$store.dispatch('users/getCurrentProfile');
  },
  // setup() {
  //   const { t, te } = useI18n();
  //   const route = useRoute();

  //   const hasActiveChildren = (match) => {
  //     return route.path.indexOf(match) !== -1;
  //   };

  //   const translate = (text) => {
  //     if (te(text)) {
  //       return t(text);
  //     } else {
  //       return text;
  //     }
  //   };
  // },
}
